import { CheckboxGroupInput, FormTab, FormTabProps, ReferenceArrayInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';

const CriteriaCheckTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Criteria Check" {...props}>
    <CraftPageSection title="Criteria Groups">
      <ReferenceArrayInput
        reference="criteria_groups"
        source="criteria_groups_transformed"
        filter={{ 'archived_at@_is_null': true }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <CheckboxGroupInput label={false} row={false} />
      </ReferenceArrayInput>
    </CraftPageSection>
  </FormTab>
);

export default CriteriaCheckTab;
