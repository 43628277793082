import gql from 'graphql-tag';
import { client } from '../data/api';

export const softDeletePortalUser = async (userId: number) =>
  client.mutate({
    mutation: gql`
      mutation SoftDeletePortalUser($userId: Int!) {
        update_users_roles(where: { users_id: { _eq: $userId } }, _set: { deleted_at: "now()" }) {
          returning {
            users_id
          }
        }
        update_notifications(where: { user_id: { _eq: $userId } }, _set: { active: false }) {
          returning {
            id
          }
        }
        update_users(where: { id: { _eq: $userId } }, _set: { deleted_at: "now()" }) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      userId,
    },
  });

export const deactivateUser = async (userId: number, orgId: number) => {
  const { data } = await client.query({
    query: gql`
      query GetUserRoles($where: users_roles_bool_exp!) {
        portal_users_roles(where: $where) {
          organization_id
        }
      }
    `,
    variables: {
      where: {
        users_id: { _eq: userId },
      },
    },
  });
  const hasOnlyOneOrg = data.portal_users_roles.length === 1;

  if (hasOnlyOneOrg) {
    return softDeletePortalUser(userId);
  }
  return client.mutate({
    mutation: gql`
      mutation DeactivateUserFromOrg($userId: Int!, $orgId: Int!) {
        update_users_roles(
          where: { users_id: { _eq: $userId }, organization_id: { _eq: $orgId } }
          _set: { deleted_at: "now()" }
        ) {
          returning {
            users_id
          }
        }
        update_notifications(
          where: { user_id: { _eq: $userId }, organization_id: { _eq: $orgId } }
          _set: { active: false }
        ) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      userId,
      orgId,
    },
  });
};
