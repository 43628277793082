import { useState } from 'react';
import {
  Button,
  ButtonProps,
  ConfirmProps,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from 'react-admin';
import { Cancel as CancelIcon, Delete as DeleteIcon } from '@mui/icons-material';

import LoadingOverlay from 'src/components/LoadingOverlay';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { DialogContentText, Typography } from '@mui/material';

// TODO: explore use in FormToolbar
export const SoftDeleteWithConfirmButton = ({
  resourceNameSingular,
  dialogOptions,
  data = { deleted_at: new Date().toISOString() },
  ...rest
}: ButtonProps & {
  resourceNameSingular: string;
  dialogOptions?: Partial<ConfirmProps>;
  data?: Record<string, unknown>;
}) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [update, { isLoading: updateLoading }] = useUpdate();

  const handleDelete = async () => {
    if (!record) return;

    try {
      await update(resource, { id: record.id, data }, { returnPromise: true });
      notify(`The item has been successfully deleted`, { type: 'success' });
      refresh();
    } catch (e) {
      notify(`There was a problem deleting the item. Please try again.`, { type: 'error' });
    }

    setDialogOpen(false);
  };

  const resourceNameTitle = resourceNameSingular.replaceAll(/^[a-z]|( [a-z])/g, (s) => s.toUpperCase());
  const dialogTitle = `Delete ${resourceNameTitle}${record.name ? ` "${record.name}"` : ''}`;
  const dialogContent = (
    <DialogContentText>
      <Typography>Are you sure you want to delete this {resourceNameSingular}?</Typography>
      ID:{' '}
      <Typography variant="code" mt={3}>
        {record.id}
      </Typography>
    </DialogContentText>
  );

  return (
    <>
      <Button label="Delete" color="error" startIcon={<DeleteIcon />} onClick={() => setDialogOpen(true)} {...rest} />
      <ConfirmDialog
        isOpen={dialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
        loading={updateLoading}
        confirm="Delete"
        confirmColor="warning"
        ConfirmIcon={DeleteIcon}
        CancelIcon={CancelIcon}
        {...dialogOptions}
      />
      <LoadingOverlay open={updateLoading} />
    </>
  );
};

export default SoftDeleteWithConfirmButton;
