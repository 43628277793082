import { FormTab, FormTabProps, required, TextInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import { JsonInput } from 'src/inputs/JsonInput';

const DetailsTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Details" {...props}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={
          <>
            <TextInput source="name" validate={required()} autoFocus />
            <JsonInput label="Search filters" source="search_filters" />
          </>
        }
        leftSX={{ mb: 2 }}
        right={<TextInput source="description" validate={required()} multiline />}
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
