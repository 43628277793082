import { DateField, Labeled, ShowBase, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { Box, Card, Grid } from '@mui/material';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';
import { CraftPageHeader } from 'src/components/CraftPageHeader';
import { JsonField } from 'src/inputs/JsonField';

const Aside = () => (
  <Box ml={2} minWidth="25rem">
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <>
            <Labeled label="Created" fullWidth>
              <DateField source="created_at" showTime />
            </Labeled>
            <Labeled label="Archived">
              <DateField source="archived_at" emptyText="N/A" showTime />
            </Labeled>
          </>
        }
        right={
          <Labeled label="Updated">
            <DateField source="updated_at" showTime />
          </Labeled>
        }
      />
    </Card>
  </Box>
);

const ShowContent = () => {
  const record = useRecordContext();

  return (
    <>
      <CraftPageHeader>Criterion Info</CraftPageHeader>

      <Box mx={2}>
        <Grid container>
          <Grid item md={8}>
            <Card sx={{ pb: 2 }}>
              <SimpleShowLayout>
                <CraftPageSection title="General">
                  <CraftTwoColumns
                    left={
                      <>
                        <Labeled>
                          <TextField label="ID" source="id" />
                        </Labeled>
                        <Labeled>
                          <TextField source="name" />
                        </Labeled>
                      </>
                    }
                    leftSX={{ gap: 2 }}
                    right={
                      <>
                        <Labeled>
                          <TextField source="description" />
                        </Labeled>
                        <Labeled>
                          <JsonField source="search_filters" />
                        </Labeled>
                      </>
                    }
                    rightSX={{ gap: 2 }}
                  />
                </CraftPageSection>
              </SimpleShowLayout>
            </Card>
          </Grid>

          <Grid item md={4}>
            <Aside />
          </Grid>
        </Grid>
      </Box>

      <ArchivedOrDeletedNotification record={record} notificationText="This Criterion is ARCHIVED" />
    </>
  );
};

export const CriteriaShow = () => (
  <ShowBase>
    <ShowContent />
  </ShowBase>
);

export default CriteriaShow;
