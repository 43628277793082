import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import gql from 'graphql-tag';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import PortalOrgsCreate from './Create';
import PortalOrgsEdit from './Edit';
import PortalOrgsList from './List';

export const OrganizationsResource: ResourceDefinition = {
  name: 'organizations',
  section: ResourceSections.PORTAL,
  icon: CorporateFareIcon,

  list: PortalOrgsList,
  create: PortalOrgsCreate,
  edit: PortalOrgsEdit,

  permissions: {
    list: ['organization:read'],
    create: ['organization:edit'],
    edit: ['organization:edit'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_organizations',
      },
      [GET_ONE]: {
        operationName: 'portal_organizations',
      },
      [GET_MANY]: {
        operationName: 'portal_organizations',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_organizations',
      },
    },
    extraFields: gql`
      {
        settings {
          data
        }
        criteria_groups {
          id
          criteria_group_id
          archived_at
        }
      }
    `,
    transformParams: (ctx) => {
      const { operation } = ctx;

      if (
        operation.type === 'GET_LIST' &&
        !!operation.params.filter &&
        'deleted_at@_is_null' in operation.params.filter
      ) {
        const deletedAtOrArchivedAtIsNull = operation.params.filter['deleted_at@_is_null'];
        if (deletedAtOrArchivedAtIsNull === true) {
          // creates _and expression
          Object.assign(operation.params.filter, {
            'archived_at@_is_null': deletedAtOrArchivedAtIsNull,
          });
        }
        if (deletedAtOrArchivedAtIsNull === false) {
          // creates _or expression
          Object.assign(operation.params.filter, {
            'deleted_at@_is_null,archived_at@_is_null': deletedAtOrArchivedAtIsNull,
          });
          delete operation.params.filter['deleted_at@_is_null'];
        }
      }

      return ctx;
    },
    excludeFields: (userPermissions) => {
      if (userPermissions?.has('organization:read')) return undefined;
      return gql`
        {
          company_data_api_key
        }
      `;
    },
  },
};
