import gql from 'graphql-tag';
import z from 'zod';
import { Identifier } from 'ra-core';

import { CompanySubscriptionSetRecord } from 'src/types';

export const companySubscriptionSetSchema = z.object({
  name: z.string().trim().nonempty(),
  description: z.string().trim().nullable(),
  company_limit: z.number().int().positive().optional(),
  dataPackages: z.record(z.string(), z.boolean().optional()).optional(),
});

type FormValues = z.infer<typeof companySubscriptionSetSchema>;

export function resolveMutationParams(
  values: FormValues,
  record: CompanySubscriptionSetRecord,
  defaultDataPackageIdSet: Set<number>,
) {
  const companySubscriptionSetParams = {
    name: values.name,
    description: values.description,
    company_limit: values.company_limit,
  };

  const { dataPackageIdsToAdd, dataPackageIdsToRemove } = resolveDataPackageChanges(
    record,
    values,
    defaultDataPackageIdSet,
  );

  return resolveUpdateMutationParams(
    record.id,
    companySubscriptionSetParams,
    dataPackageIdsToAdd,
    dataPackageIdsToRemove,
  );
}

function resolveDataPackageChanges(
  record: CompanySubscriptionSetRecord,
  values: FormValues,
  defaultDataPackageIdSet: Set<number>,
) {
  const result: {
    dataPackageIdsToAdd: number[];
    dataPackageIdsToRemove: number[];
  } = {
    dataPackageIdsToAdd: [],
    dataPackageIdsToRemove: [],
  };

  const { dataPackages } = values;
  if (!dataPackages) return result;

  const currentlyEnabledPackageIdSet = new Set(
    record.data_packages_xrefs.map(({ data_package_id }) => data_package_id),
  );

  // collect packages to be enabled
  for (const [enabledPackageIdKey, value] of Object.entries(dataPackages)) {
    if (!value) continue;
    // data package entry keys are IDs prefixed with "id:"
    const packageID = parseInt(enabledPackageIdKey.split(':')[1], 10);

    const isCurrentlyEnabled = currentlyEnabledPackageIdSet.has(packageID);
    if (isCurrentlyEnabled) continue;

    result.dataPackageIdsToAdd.push(packageID);
  }

  // collect packages to be disabled
  for (const currentlyEnabledPackageID of currentlyEnabledPackageIdSet) {
    if (!defaultDataPackageIdSet.has(currentlyEnabledPackageID) && !dataPackages[`id:${currentlyEnabledPackageID}`]) {
      result.dataPackageIdsToRemove.push(currentlyEnabledPackageID);
    }
  }

  return result;
}

const genUpdateCompanySubscriptionSetGQL = (
  variableDeclarations: string[] = [],
  mutationSelections: string[] = [],
) => gql`
  mutation updateCompanySubscriptionSet(
    $companySubscriptionSetID: bigint!
    $companySubscriptionSetParams: company_subscription_sets_set_input!
    ${variableDeclarations.join('\n,')}
  ) {
    update_company_subscription_sets(
      where: { id: { _eq: $companySubscriptionSetID } }
      _set: $companySubscriptionSetParams
    ) {
      affected_rows
    }
    ${mutationSelections.join('')}
  }
`;

export const updateCompanySubscriptionSetGQL = genUpdateCompanySubscriptionSetGQL();

const addDataPackagesFragment = /* GraphQL */ `
  insert_company_subscription_sets_data_packages(objects: $newDataPackages) {
    affected_rows
  }
`;

const removeDataPackagesFragment = /* GraphQL */ `
  delete_company_subscription_sets_data_packages(
    where: {
      company_subscription_set_id: { _eq: $companySubscriptionSetID },
      data_package_id: { _in: $dataPackageIdsToRemove }
    }
  ) {
    affected_rows
  }
`;

function resolveUpdateMutationParams(
  companySubscriptionSetID: Identifier,
  companySubscriptionSetParams: Record<string, unknown>,
  dataPackageIdsToAdd: number[],
  dataPackageIdsToRemove: number[],
) {
  const variableDeclarations = [];
  const mutationSelections = [];
  if (dataPackageIdsToAdd.length) {
    variableDeclarations.push('$newDataPackages: [company_subscription_sets_data_packages_insert_input!]!');
    mutationSelections.push(addDataPackagesFragment);
  }
  if (dataPackageIdsToRemove.length) {
    variableDeclarations.push('$dataPackageIdsToRemove: [Int!]');
    mutationSelections.push(removeDataPackagesFragment);
  }

  const newDataPackages = dataPackageIdsToAdd.map((id) => ({
    company_subscription_set_id: companySubscriptionSetID,
    data_package_id: id,
  }));

  return {
    mutation: genUpdateCompanySubscriptionSetGQL(variableDeclarations, mutationSelections),
    variables: {
      companySubscriptionSetID,
      companySubscriptionSetParams,
      ...(newDataPackages.length && { newDataPackages }),
      ...(dataPackageIdsToRemove.length && { dataPackageIdsToRemove }),
    },
  };
}
