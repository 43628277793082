import gql from 'graphql-tag';
import PeopleIcon from '@mui/icons-material/People';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import PortalUsersEdit from './Edit';
import PortalUsersList from './List';

export const UsersResource: ResourceDefinition = {
  name: 'users',
  section: ResourceSections.PORTAL,
  icon: PeopleIcon,

  list: PortalUsersList,
  edit: PortalUsersEdit,
  permissions: {
    list: ['organizationUser:read'],
    edit: ['organizationUser:edit'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_users',
      },
      [GET_ONE]: {
        operationName: 'portal_users',
      },
      [GET_MANY]: {
        operationName: 'portal_users',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_users',
      },
    },
    extraFields: gql`
      {
        roles {
          deleted_at
          roles_id
          users_id
          organization_id
        }
      }
    `,
  },
};
