import gql from 'graphql-tag';
import { useEffect } from 'react';
import { CheckboxGroupInput, FormTab, FormTabProps, required, useNotify } from 'react-admin';
import { Box, Grid, GridProps } from '@mui/material';
import { FieldValues, UseFormSetValue, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import client from 'src/data/api';
import { CraftPageSection } from 'src/components/CraftPageSection';
import BooleanInput, { BooleanInputProps } from 'src/inputs/BooleanInput';
import NestedInputWrapper from 'src/inputs/NestedInputWrapper';
import {
  API_CLIENT_AVAILABILITY_READONLY_FIELD_PATHS,
  API_CLIENT_DEFAULT_FIELD_PATHS,
  CLIENT_API_FIELD_POLICIES_BY_FIELD_PATH,
} from 'src/utils/defaults/Constants';

/**
 * Field paths whose access policies allow access to all of their children by default.
 * TODO: Until we confirm all fields that should be covered by their parents' policies and/or
 * policies of other fields, we need an explicit list.
 */
const fieldPathsEncompassingChildrenSet = new Set([
  'foreignInfluenceTechnologyTransfer',
  'foreignInfluenceTechnologyTransfer.peopleLevelRisk',
  'foreignInfluenceTechnologyTransfer.peopleLevelRisk.risk',
]);

const configurableChildFieldSet = new Set([
  'foreignInfluenceTechnologyTransfer.peopleLevelRisk',
  'foreignInfluenceTechnologyTransfer.peopleLevelRisk.risk.report',
]);

function getFieldInputProps(
  fieldPath: string,
  fieldValueMap: Record<string, boolean>,
  setValue: UseFormSetValue<FieldValues>,
) {
  const fieldPathSegments = fieldPath.split('.');
  const parentFieldPath = fieldPathSegments.length > 1 ? fieldPathSegments.slice(0, -1).join('.') : null;

  const result: Partial<BooleanInputProps> = {};

  if (API_CLIENT_DEFAULT_FIELD_PATHS.has(fieldPath) || API_CLIENT_AVAILABILITY_READONLY_FIELD_PATHS.has(fieldPath)) {
    result.readOnly = true;
  }

  if (API_CLIENT_DEFAULT_FIELD_PATHS.has(fieldPath)) {
    result.defaultValue = true;
  }

  if (
    parentFieldPath &&
    fieldPathsEncompassingChildrenSet.has(parentFieldPath) &&
    !configurableChildFieldSet.has(fieldPath)
  ) {
    result.readOnly = true;
  }

  switch (fieldPath) {
    case 'securityRatings.factors':
      result.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;

        if (fieldValueMap.securityRatingsFactorsAvailable !== checked) {
          setValue('fields.securityRatingsFactorsAvailable', checked);
        }
      };
      break;
    default:
  }

  return result;
}

const FieldPolicyInputGroupWrapper = ({ children }: Pick<GridProps, 'children'>) => (
  <Grid container item flex={1} pl={2} flexDirection="column" justifyContent="flex-start" borderLeft="1px solid #aaa">
    {children}
  </Grid>
);

interface FieldParams {
  name: string;
  isDeprecated?: boolean;
  children?: FieldParams[];
}

const SourcesInputGroup = ({
  fieldSlashPath,
  sources,
}: {
  fieldSlashPath: string;
  sources: { id: string; name: string }[];
}) => {
  const defaultValue = [sources.find((src) => src.name === 'craft')?.id ?? sources[0].id];

  return (
    <FieldPolicyInputGroupWrapper>
      <CheckboxGroupInput
        label="Sources"
        source={`sources.${fieldSlashPath}`}
        choices={sources}
        defaultValue={defaultValue}
        validate={required()}
        fullWidth
        sx={{ m: 0 }}
        helperText={false}
      />
    </FieldPolicyInputGroupWrapper>
  );
};

const PolicyFiltersInputGroup = ({ fieldPath, fieldSlashPath }: { fieldPath: string; fieldSlashPath: string }) => {
  const policies = CLIENT_API_FIELD_POLICIES_BY_FIELD_PATH[fieldPath]?.filters;
  if (!policies) return null;

  return (
    <FieldPolicyInputGroupWrapper>
      <CheckboxGroupInput
        label="Filters"
        source={`rowLevelAccessPolicy.${fieldSlashPath}.filters`}
        choices={policies}
        fullWidth
        sx={{ m: 0 }}
        helperText={false}
      />
    </FieldPolicyInputGroupWrapper>
  );
};

const FieldSwitch = ({
  field,
  parentValuePath,
  sourcesByField,
}: {
  field: FieldParams;
  parentValuePath?: string;
  sourcesByField: Partial<Record<string, string[]>>;
}) => {
  const { watch, setValue } = useFormContext();
  const fieldValueMap: Record<string, boolean> = watch('fields', {});

  const isRootField = !parentValuePath;
  // we use "/" instead of "." to avoid conversion of form values to obj.
  const valuePath = `${isRootField ? 'fields.' : `${parentValuePath}/`}${field.name}`;

  const fieldSlashPath = valuePath.split('.')[1];
  const fieldPathSegments = fieldSlashPath.split('/');
  const fieldPath = fieldPathSegments.join('.');
  const parentFieldPath = parentValuePath && fieldPathSegments.slice(0, -1).join('.');
  const fieldIsChecked = !!fieldValueMap[fieldSlashPath];

  useEffect(() => {
    // if parent's policy covers this field, enable this field (parent is checked/enabled if this
    // component renders)
    if (
      parentFieldPath &&
      fieldPathsEncompassingChildrenSet.has(parentFieldPath) &&
      !configurableChildFieldSet.has(fieldPath) &&
      !fieldIsChecked
    ) {
      setValue(valuePath, true);
    }
  }, [fieldValueMap]);

  useEffect(() => {
    // disable all descendants when this field disabled
    if (!field.children || fieldIsChecked) return;
    function disableChildrenDeep(currentField: FieldParams, currentSlashPath: string) {
      for (const child of currentField.children ?? []) {
        const childSlashPath = `${currentSlashPath}/${child.name}`;
        if (fieldValueMap[childSlashPath]) setValue(`fields.${childSlashPath}`, false);
        disableChildrenDeep(child, childSlashPath);
      }
    }
    disableChildrenDeep(field, fieldSlashPath);
  }, [fieldIsChecked]);

  const genChildrenFields = () =>
    !!field.children && (
      <NestedInputWrapper>
        {field.children.map((child) => (
          <FieldSwitch
            key={`${fieldPath}.${child.name}`}
            field={child}
            parentValuePath={valuePath}
            sourcesByField={sourcesByField}
          />
        ))}
      </NestedInputWrapper>
    );

  const inputProps = getFieldInputProps(fieldPath, fieldValueMap, setValue);
  const sources = sourcesByField[fieldPath]?.map((src) => ({ id: src, name: src.split(':')[1] }));

  return (
    <Box key={valuePath}>
      <Grid container alignItems="stretch">
        <BooleanInput
          source={valuePath}
          label={field.isDeprecated ? `${field.name} (DEPRECATED)` : field.name}
          helperText={false}
          sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
          {...inputProps}
        />
        {fieldIsChecked && sources && <SourcesInputGroup fieldSlashPath={fieldSlashPath} sources={sources} />}
        {fieldIsChecked && <PolicyFiltersInputGroup fieldPath={fieldPath} fieldSlashPath={fieldSlashPath} />}
      </Grid>
      {fieldIsChecked && genChildrenFields()}
    </Box>
  );
};

const FieldsTab = (props: Omit<FormTabProps, 'label'>) => {
  const notify = useNotify();

  const {
    data: fieldsData,
    loading: fieldsLoading,
    error: fieldsError,
  } = useQuery<{ externalApiClientFields: FieldParams[] }>(
    gql`
      query externalApiClientFields {
        externalApiClientFields
      }
    `,
    { client },
  );

  const {
    data: fieldSourcesData,
    loading: fieldSourcesLoading,
    error: fieldSourcesError,
  } = useQuery<{
    external_api_policies: { field_type: string }[];
  }>(
    gql`
      query external_api_policies {
        external_api_policies(distinct_on: [field_type], where: { field_type: { _ilike: "%:%" } }) {
          field_type
        }
      }
    `,
    { client },
  );

  if (fieldsError || fieldSourcesError) {
    notify('Error fetching data. Please refresh the page to try again.', { type: 'error' });
  }

  const sourcesByField: Partial<Record<string, string[]>> = {};
  fieldSourcesData?.external_api_policies.reduce((acc, src) => {
    const fieldPath = src.field_type.split(':')[0];
    acc[fieldPath] ||= [];
    acc[fieldPath]?.push(src.field_type);
    return acc;
  }, sourcesByField);

  return (
    <FormTab label="Fields" {...props}>
      <CraftPageSection title="Company Fields" loading={fieldsLoading || fieldSourcesLoading}>
        {fieldsData?.externalApiClientFields.map((field) => (
          <FieldSwitch key={field.name} field={field} sourcesByField={sourcesByField} />
        ))}
      </CraftPageSection>
    </FormTab>
  );
};

export default FieldsTab;
