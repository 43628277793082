import gql from 'graphql-tag';
import z from 'zod';

import client from 'src/data/api';

export const criteriaGroupSchema = z.object({
  name: z.string().trim().nonempty(),
  criteria: z.array(z.string().uuid()).min(1),
});

export const createCriteriaGroup = (criteriaGroupParams: z.infer<typeof criteriaGroupSchema>) =>
  client.mutate<{ insert_criteria_groups_one: { id: string } }>({
    mutation: gql`
      mutation insert_criteria_groups_one($criteriaGroupParams: criteria_groups_insert_input!) {
        insert_criteria_groups_one(object: $criteriaGroupParams) {
          id
        }
      }
    `,
    variables: {
      criteriaGroupParams: {
        name: criteriaGroupParams.name,
        criteria: {
          data: criteriaGroupParams.criteria.map((id) => ({ criterion_id: id })),
        },
      },
    },
    errorPolicy: 'all',
  });
