import { CreateButton, ShowButton, TextField, TopToolbar, WrapperField } from 'react-admin';

import CraftList from 'src/components/CraftList';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';
import ClearFiltersButton from 'src/components/ClearFiltersButton';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';
import { usePermissions } from 'src/hooks';
import SoftDeleteWithConfirmButton from 'src/components/SoftDeleteWithConfirmButton';

const columnStyles = {
  actions: {
    width: '16rem',
    '& :nth-child(n + 2)': {
      ml: 0.5,
    },
  },
};

const filters = [
  <AuditBasedStatusFilterInput
    key="statusFilter"
    label="Status"
    source="archived_at@_is_null"
    deletedLabel="Archived"
    alwaysOn
  />,
];

const ListActions = ({ canCreate }: { canCreate: boolean }) => (
  <TopToolbar>
    <ClearFiltersButton />
    {canCreate && <CreateButton />}
  </TopToolbar>
);

export const CriteriaList = () => {
  const { permissions } = usePermissions();

  const canCreate = permissions.has('criteria:edit');
  const canDelete = permissions.has('criteria:delete');

  const bulkActionButtons = canDelete && (
    <BulkSoftDeleteButton label="Delete Selected" data={{ archived_at: 'now()' }} />
  );

  return (
    <CraftList
      title="Criteria"
      filters={filters}
      filterDefaultValues={{ 'archived_at@_is_null': true }}
      actions={<ListActions canCreate={canCreate} />}
      sort={{ field: 'name', order: 'ASC' }}
      dataGridProps={{ bulkActionButtons }}
      columnStyles={columnStyles}
    >
      <TextField source="name" />
      <TextField source="description" />
      <WrapperField label="Actions" headerClassName="column-actions" cellClassName="column-actions">
        <ShowButton />
        <SoftDeleteWithConfirmButton resourceNameSingular="criterion" data={{ archived_at: 'now()' }} />
      </WrapperField>
    </CraftList>
  );
};

export default CriteriaList;
