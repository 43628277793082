import { ReactNode, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { SaveButton, Toolbar, ToolbarProps, DeleteWithConfirmButton, useRecordContext } from 'react-admin';
import { Cancel as CancelIcon, Delete as DeleteIcon } from '@mui/icons-material';

import BackButton from './BackButton';
import ConfirmDialog from './ConfirmDialog';

interface DeleteOptions {
  showDeleteButton?: boolean;
  dialogTitle?: string | undefined;
  dialogContent?: ReactNode;
  onConfirmDelete?: (() => void) | undefined;
}

interface FormToolbarProps extends ToolbarProps {
  disableSave?: boolean;
  deleteOptions?: DeleteOptions;
}

const FormToolbar = (props: FormToolbarProps) => {
  const record = useRecordContext();

  const { deleteOptions, disableSave, ...baseProps } = props;

  const {
    showDeleteButton = true,
    dialogTitle: confirmDeleteDialogTitle = 'ra.message.delete_title',
    dialogContent: confirmDeleteDialogContent = 'ra.message.delete_content',
    onConfirmDelete,
  } = deleteOptions || {};

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogConfirm = () => {
    onConfirmDelete && onConfirmDelete();
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <Toolbar {...baseProps}>
      <Grid container justifyContent="space-between">
        <BackButton label="Cancel" />
        <SaveButton disabled={!!disableSave} />
        {showDeleteButton && onConfirmDelete && (
          <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDialogOpen}>
            Delete
          </Button>
        )}
        {showDeleteButton && onConfirmDelete && (
          <ConfirmDialog
            translateOptions={{ id: record?.id, name: record?.name }}
            isOpen={dialogOpen}
            title={confirmDeleteDialogTitle}
            content={confirmDeleteDialogContent}
            onConfirm={handleDialogConfirm}
            onClose={handleDialogClose}
            confirm="Delete"
            confirmColor="warning"
            ConfirmIcon={DeleteIcon}
            CancelIcon={CancelIcon}
          />
        )}
        {showDeleteButton && !onConfirmDelete && (
          <DeleteWithConfirmButton
            confirmTitle={confirmDeleteDialogTitle}
            confirmContent={confirmDeleteDialogContent}
          />
        )}
      </Grid>
    </Toolbar>
  );
};

export default FormToolbar;
