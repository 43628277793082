import { Box } from '@mui/material';
import {
  EmailField,
  FormDataConsumer,
  FormTab,
  Labeled,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
  TransformData,
  useGetRecordId,
  useRefresh,
  WithRecord,
} from 'react-admin';
import { Center } from 'src/components/Center';
import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import NameIdReferenceField from 'src/fields/NameIdReferenceField';
import UsersEditAside from './EditAside';
import { CraftDateInput } from 'src/components/CraftDateInput';
import { UserRoleField } from './UserRoleField';
import { TransformedArrayField } from '../../../fields/TransformedArrayField';
import { softDeletePortalUser } from '../../../utils/deactivateUser';
import { UserRole } from '../../../types';

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  return result;
};

const subscriptionsFilters = [
  <TextInput key="topic" source="topic" alwaysOn />,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
];

export const PortalUsersEdit = () => {
  const userId = useGetRecordId();
  const refresh = useRefresh();

  return (
    <CraftEdit aside={<UsersEditAside />} transform={transformValues}>
      <CraftTabbedForm
        formType="edit"
        deleteOptions={{
          deletePermission: 'organizationUser:delete',
          dialogContent: (
            <>
              Deleting the user will delete him from all associated orgs. If you want to deactivate from specific orgs
              hit deactivate in User Roles section.
            </>
          ),
          onConfirmDelete: async () => {
            await softDeletePortalUser(Number(userId));
            refresh();
          },
        }}
      >
        <FormTab label="Details">
          <CraftPageSection title="User details">
            <CraftTwoColumns
              left={
                <Box display="flex" flexDirection="column" gap={2.5}>
                  <Labeled label="Craft ID">
                    <TextField fullWidth source="id" />
                  </Labeled>
                  <Labeled label="Auth ID">
                    <TextField fullWidth source="auth_id" />
                  </Labeled>
                  <Labeled label="Email">
                    <EmailField fullWidth source="email" />
                  </Labeled>
                </Box>
              }
              right={
                <>
                  <TextInput fullWidth source="name" />
                  <TextInput fullWidth source="first_name" />
                  <TextInput fullWidth source="last_name" />
                  <TextInput fullWidth source="nickname" />
                </>
              }
            />
          </CraftPageSection>
          <FormDataConsumer>
            {({ formData }) =>
              formData.roles.filter((role: UserRole) => !role.deleted_at).length ? (
                <CraftPageSection title="User roles">
                  <TransformedArrayField<UserRole> source="roles" filter={(role) => !role.deleted_at}>
                    <SingleFieldList linkType={false}>
                      <UserRoleField />
                    </SingleFieldList>
                  </TransformedArrayField>
                </CraftPageSection>
              ) : null
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Teams">
          <CraftPageSection title="User teams">
            <Center>Coming soon</Center>
          </CraftPageSection>
        </FormTab>
        <FormTab label="Alert Subscriptions">
          <WithRecord
            render={(record) => (
              <CraftPageSection title="User Subscriptions">
                <CraftStandaloneList
                  resource="user_subscriptions"
                  perPage={10}
                  filters={subscriptionsFilters}
                  filter={{ user_id: record.id }}
                  dataGridProps={{ empty: <Center>No subscriptions found</Center> }}
                >
                  <TextField source="user_id" />
                  <ReferenceField label="Company" reference="companies" source="company_id" sortable={false}>
                    <NameIdReferenceField />
                  </ReferenceField>
                  <TextField source="topic" />
                </CraftStandaloneList>
              </CraftPageSection>
            )}
          />
        </FormTab>
      </CraftTabbedForm>
    </CraftEdit>
  );
};

export default PortalUsersEdit;
