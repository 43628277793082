import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';

export const CriterionCreate = () => {
  const defaultValues = { search_filters: {} };

  return (
    <CraftCreate header="Create Criterion">
      <CraftTabbedForm formType="create" defaultValues={defaultValues}>
        <DetailsTab />
      </CraftTabbedForm>
    </CraftCreate>
  );
};

export default CriterionCreate;
