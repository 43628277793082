import { FormTab, FormTabProps, required, useRecordContext } from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import IntegerInput from 'src/inputs/IntegerInput';
import TextInput from 'src/inputs/TextInput';
import { CompanySubscriptionSetRecord } from 'src/types';
import { CraftPageSection } from 'src/components/CraftPageSection';

const DetailsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => {
  const record = useRecordContext<CompanySubscriptionSetRecord>();

  return (
    <FormTab label="Details" {...rest}>
      <CraftPageSection title="General">
        <CraftTwoColumns
          left={
            <>
              <TextInput
                source="name"
                validate={required()}
                readOnly={record?.all_companies}
                autoFocus={mode === 'create'}
                fullWidth
              />
              <TextInput source="description" multiline readOnly={record?.all_companies} />
            </>
          }
          right={
            (mode === 'create' || !record?.all_companies) && (
              <IntegerInput source="company_limit" validate={required()} fullWidth />
            )
          }
        />
      </CraftPageSection>
    </FormTab>
  );
};

export default DetailsTab;
