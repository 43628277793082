import { FormTab, FormTabProps, required, TextInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';

const DetailsTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Details" {...props}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={<TextInput source="name" validate={required()} autoFocus />}
        leftSX={{ mb: 2 }}
        right={null}
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
