import { Checklist } from '@mui/icons-material';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import CriteriaList from './List';
import CriteriaShow from './Show';
import CriterionCreate from './Create';
import { ResourceDefinition, ResourceSections } from 'src/types';

export const CriteriaResource: ResourceDefinition = {
  name: 'criteria',
  section: ResourceSections.PORTAL,
  icon: Checklist,

  list: CriteriaList,
  show: CriteriaShow,
  create: CriterionCreate,

  permissions: {
    list: ['criteria:read'],
    create: ['criteria:edit'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_criteria',
      },
      [GET_ONE]: {
        operationName: 'portal_criteria',
      },
      [GET_MANY]: {
        operationName: 'portal_criteria',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_criteria',
      },
    },
  },
};
