import { Typography } from '@mui/material';
import { CheckboxGroupInput, FormTab, FormTabProps, ReferenceArrayInput } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';

const CriteriaTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Criteria" {...props}>
    <CraftPageSection title="Criteria">
      <Typography variant="caption">(Select at least 1)</Typography>
      <CraftTwoColumns
        left={
          <ReferenceArrayInput
            reference="criteria"
            source="criteria"
            filter={{ 'archived_at@_is_null': true }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <CheckboxGroupInput label={false} row={false} />
          </ReferenceArrayInput>
        }
        leftSX={{ mb: 2 }}
        right={null}
      />
    </CraftPageSection>
  </FormTab>
);

export default CriteriaTab;
