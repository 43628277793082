import { useNotify, useRedirect } from 'react-admin';

import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import CriteriaTab from './formTabs/Criteria';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { createCriteriaGroup, criteriaGroupSchema } from './helpers';

export const CriteriaGroupCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const defaultValues = { criteria: [] };

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = criteriaGroupSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { data, errors } = await createCriteriaGroup(values);

    if (errors) {
      notify(
        'Oops. There was a problem creating the criteria group. Please refresh and try again, or contact IT Support.',
        { type: 'error', autoHideDuration: 10000 },
      );
      console.error('Error response on criteria group create:', errors);
      return undefined;
    }

    const criteriaGroupID = data?.insert_criteria_groups_one.id;
    if (!criteriaGroupID) {
      notify('Unable to redirect to criteria group: ID not returned', { type: 'error' });
      return redirect('list', 'criteria_groups');
    }

    return redirect('show', 'criteria_groups', criteriaGroupID);
  };

  return (
    <CraftCreate header="Create Criteria Group">
      <CraftTabbedForm formType="create" defaultValues={defaultValues} onSubmit={handleSave} shouldUnregister>
        <DetailsTab />
        <CriteriaTab />
      </CraftTabbedForm>
    </CraftCreate>
  );
};

export default CriteriaGroupCreate;
